import React from "react";

import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/shifts/Hero";
import Content1 from "../../sections/shifts/Content1";
import Content3 from "../../sections/shifts/Content3";
import Progress from "../../sections/shifts/Progress";
import CTA from "../../components/CTA/CTA";

const Shifts = () => {
  return (
    <>
      <PageWrapper>
        <Content1 className="pt-20 pt-lg-34" />
        <Progress className="pb-lg-21" />
        <CTA className="bg-buttercup py-10" />
        <Hero className="pt-14 pt-lg-34 pb-8 pb-lg-33" />
        <Content3 className="pb-13 pb-lg-28" />
      </PageWrapper>
    </>
  );
};
export default Shifts;
